<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">transaction detail</h1>

    <transaction v-if="transaction" :transaction="transaction" />

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import toggleLoading from '@/mixins/toggle-loading'
const Transaction = () => import('@/components/Transaction')

export default {
  computed: {
    ...mapState('transaction', ['transaction']),

    tnxError() {
      return this.$route.query.error
    }
  },

  mixins: [toggleLoading],

  components: {
    Transaction
  },

  methods: {
    ...mapActions('transaction', ['getTransaction']),

    ...mapMutations('transaction', ['CLEAR_TRANSACTION_DATA'])
  },

  created() {
    this['CLEAR_TRANSACTION_DATA']()
    this.getTransaction({ id: this.$route.params.id, isError: this.tnxError }).finally(() => {
      this.toggleLoading()
    })
  }
}
</script>
